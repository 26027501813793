import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => !!localStorage.getItem('token'));
    const [userName, setUserName] = useState('');

    useEffect(() => {
        const fetchUser = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                };
                try {
                    const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/auth/user`, config);
                    setUserName(res.data.name);
                    setIsAuthenticated(true);
                } catch (err) {
                    console.error(err);
                }
            }
        };

        fetchUser();
    }, []);

    const login = async (token) => {
        localStorage.setItem('token', token);
        setIsAuthenticated(true);
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token
                }
            };
            const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/auth/user`, config);
            setUserName(res.data.name);
        } catch (err) {
            console.error(err);
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        setUserName('');
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, userName }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
