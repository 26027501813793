import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../authContext';
import './signin.css'; // Import the CSS file for additional styles

const AddMeal = () => {
    const [description, setDescription] = useState('');
    const [protein, setProtein] = useState('');
    const [carbs, setCarbs] = useState('');
    const [fat, setFat] = useState('');
    const [calories, setCalories] = useState('');
    const [date, setDate] = useState(() => {
        const today = new Date();
        return today.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
    });
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isAuthenticated) {
            setError('You must be logged in to add a meal');
            return;
        }
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token
                }
            };
            const body = { description, protein, carbs, fat, calories, date };
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/meals`, body, config);
            navigate('/dashboard');
        } catch (err) {
            if (err.response && err.response.data) {
                setError(err.response.data.msg);
            } else {
                setError('Server error');
            }
        }
    };

    const handleFetchMacros = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/chatgpt/estimate-macros`, { description });
            const { protein, carbs, fat, calories } = response.data;
            setProtein(protein);
            setCarbs(carbs);
            setFat(fat);
            setCalories(calories);
        } catch (error) {
            console.error('Error fetching macros:', error);
            setError('Error fetching macros from ChatGPT');
        }
    };

    return (
        <div className="container mt-4">
            <h2>Add a New Meal</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="description" className="form-label">Description</label>
                    <input
                        type="text"
                        className="form-control"
                        id="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    />
                </div>
                <button type="button" className="btn btn-info mb-3" onClick={handleFetchMacros}>
                    Fetch estimated macros via ChatGPT
                </button>
                <div className="mb-3">
                    <label htmlFor="protein" className="form-label">Protein (g)</label>
                    <input
                        type="number"
                        className="form-control"
                        id="protein"
                        value={protein}
                        onChange={(e) => setProtein(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="carbs" className="form-label">Carbs (g)</label>
                    <input
                        type="number"
                        className="form-control"
                        id="carbs"
                        value={carbs}
                        onChange={(e) => setCarbs(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="fat" className="form-label">Fat (g)</label>
                    <input
                        type="number"
                        className="form-control"
                        id="fat"
                        value={fat}
                        onChange={(e) => setFat(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="calories" className="form-label">Calories</label>
                    <input
                        type="number"
                        className="form-control"
                        id="calories"
                        value={calories}
                        onChange={(e) => setCalories(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="date" className="form-label">Date</label>
                    <input
                        type="date"
                        className="form-control"
                        id="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary w-100">Add Meal</button>
                {error && <div className="alert alert-danger mt-3">{error}</div>}
            </form>
        </div>
    );
};

export default AddMeal;
