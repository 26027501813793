import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../authContext';

const EditMeal = () => {
    const [description, setDescription] = useState('');
    const [protein, setProtein] = useState('');
    const [carbs, setCarbs] = useState('');
    const [fat, setFat] = useState('');
    const [calories, setCalories] = useState('');
    const [date, setDate] = useState('');
    const [error, setError] = useState('');
    const { isAuthenticated } = useAuth();
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMeal = async () => {
            if (isAuthenticated) {
                try {
                    const token = localStorage.getItem('token');
                    const config = {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-auth-token': token
                        }
                    };
                    const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/meals/${id}`, config);
                    const meal = res.data;
                    setDescription(meal.description);
                    setProtein(meal.protein);
                    setCarbs(meal.carbs);
                    setFat(meal.fat);
                    setCalories(meal.calories);
                    setDate(meal.date.split('T')[0]); // Format date as YYYY-MM-DD
                } catch (err) {
                    console.error(err);
                }
            }
        };

        fetchMeal();
    }, [isAuthenticated, id]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isAuthenticated) {
            setError('You must be logged in to edit a meal');
            return;
        }
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token
                }
            };
            const body = { description, protein, carbs, fat, calories, date };
            await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/meals/${id}`, body, config);
            navigate(`/meal/${date}`);
        } catch (err) {
            if (err.response && err.response.data) {
                setError(err.response.data.msg);
            } else {
                setError('Server error');
            }
        }
    };

    return (
        <div className="container mt-4">
            <h2>Edit Meal</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="description" className="form-label">Description</label>
                    <input
                        type="text"
                        className="form-control"
                        id="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="protein" className="form-label">Protein (g)</label>
                    <input
                        type="number"
                        className="form-control"
                        id="protein"
                        value={protein}
                        onChange={(e) => setProtein(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="carbs" className="form-label">Carbs (g)</label>
                    <input
                        type="number"
                        className="form-control"
                        id="carbs"
                        value={carbs}
                        onChange={(e) => setCarbs(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="fat" className="form-label">Fat (g)</label>
                    <input
                        type="number"
                        className="form-control"
                        id="fat"
                        value={fat}
                        onChange={(e) => setFat(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="calories" className="form-label">Calories</label>
                    <input
                        type="number"
                        className="form-control"
                        id="calories"
                        value={calories}
                        onChange={(e) => setCalories(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="date" className="form-label">Date</label>
                    <input
                        type="date"
                        className="form-control"
                        id="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary w-100">Update Meal</button>
                {error && <div className="alert alert-danger mt-3">{error}</div>}
            </form>
        </div>
    );
};

export default EditMeal;
