import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../authContext';

const MealDetails = () => {
    const [meals, setMeals] = useState([]);
    const [loading, setLoading] = useState(true);
    const { isAuthenticated } = useAuth();
    const { date } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMeals = async () => {
            if (isAuthenticated) {
                try {
                    const token = localStorage.getItem('token');
                    const config = {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-auth-token': token
                        }
                    };
                    const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/meals?date=${date}`, config);
                    setMeals(res.data);
                } catch (err) {
                    console.error(err);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchMeals();
    }, [isAuthenticated, date]);

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this meal?')) {
            try {
                const token = localStorage.getItem('token');
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                };
                await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/meals/${id}`, config);
                setMeals(meals.filter(meal => meal._id !== id));
            } catch (err) {
                console.error(err);
            }
        }
    };

    return (
        <div className="container mt-4">
            <h2>Meal Details for {date}</h2>
            {loading ? (
                <div className="alert alert-info">Loading...</div>
            ) : meals.length === 0 ? (
                <div className="alert alert-warning">No meals found for this date.</div>
            ) : (
                <table className="table table-striped mt-4">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Protein (g)</th>
                            <th>Carbs (g)</th>
                            <th>Fat (g)</th>
                            <th>Calories</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {meals.map((meal) => (
                            <tr key={meal._id}>
                                <td>{meal.description}</td>
                                <td>{meal.protein}</td>
                                <td>{meal.carbs}</td>
                                <td>{meal.fat}</td>
                                <td>{meal.calories}</td>
                                <td>
                                    <Link to={`/edit-meal/${meal._id}`}>
                                        <button className="btn btn-warning btn-sm me-2">Edit</button>
                                    </Link>
                                    <button
                                        className="btn btn-danger btn-sm"
                                        onClick={() => handleDelete(meal._id)}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <button className="btn btn-secondary mt-4" onClick={() => navigate('/dashboard')}>Back to Dashboard</button>
        </div>
    );
};

export default MealDetails;
