import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import AddMeal from './components/AddMeal';
import MealDetails from './components/MealDetails';
import EditMeal from './components/EditMeal';
import { AuthProvider, useAuth } from './authContext';

function PrivateRoute({ children }) {
    const { isAuthenticated } = useAuth();
    return isAuthenticated ? children : <Navigate to="/login" />;
}

function App() {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/dashboard" element={
                        <PrivateRoute>
                            <Dashboard />
                        </PrivateRoute>
                    } />
                    <Route path="/add-meal" element={
                        <PrivateRoute>
                            <AddMeal />
                        </PrivateRoute>
                    } />
                    <Route path="/meal/:date" element={
                        <PrivateRoute>
                            <MealDetails />
                        </PrivateRoute>
                    } />
                    <Route path="/edit-meal/:id" element={
                        <PrivateRoute>
                            <EditMeal />
                        </PrivateRoute>
                    } />
                    <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
            </Router>
        </AuthProvider>
    );
}

export default App;