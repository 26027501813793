import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../authContext';

const Dashboard = () => {
    const [meals, setMeals] = useState([]);
    const [loading, setLoading] = useState(true);
    const { isAuthenticated, logout, userName } = useAuth();

    useEffect(() => {
        const fetchMeals = async () => {
            if (isAuthenticated) {
                try {
                    const token = localStorage.getItem('token');
                    const config = {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-auth-token': token
                        }
                    };
                    const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/meals`, config);
                    setMeals(res.data);
                } catch (err) {
                    console.error(err);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchMeals();
    }, [isAuthenticated]);

    const aggregateMeals = (meals) => {
        const aggregated = meals.reduce((acc, meal) => {
            const date = meal.date.split('T')[0];
            if (!acc[date]) {
                acc[date] = {
                    count: 0,
                    protein: 0,
                    carbs: 0,
                    fat: 0,
                    calories: 0
                };
            }
            acc[date].count += 1;
            acc[date].protein += meal.protein;
            acc[date].carbs += meal.carbs;
            acc[date].fat += meal.fat;
            acc[date].calories += meal.calories;
            return acc;
        }, {});

        return Object.keys(aggregated).map(date => ({
            date,
            ...aggregated[date]
        }));
    };

    const aggregatedMeals = aggregateMeals(meals);

    return (
        <div className="container mt-4">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h2>Hi {userName}, Here are your historical meals</h2>
                <button className="btn btn-danger" onClick={logout}>Logout</button>
            </div>
            {loading ? (
                <div className="alert alert-info">Loading...</div>
            ) : meals.length === 0 ? (
                <div className="alert alert-warning">Please add a meal to view your history.</div>
            ) : (
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th># of meals</th>
                            <th>Protein (g)</th>
                            <th>Carbs (g)</th>
                            <th>Fat (g)</th>
                            <th>Total calories</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {aggregatedMeals.map((meal) => (
                            <tr key={meal.date}>
                                <td>{meal.date}</td>
                                <td>{meal.count}</td>
                                <td>{meal.protein}</td>
                                <td>{meal.carbs}</td>
                                <td>{meal.fat}</td>
                                <td>{meal.calories}</td>
                                <td>
                                    <Link to={`/meal/${meal.date}`}>
                                        <button className="btn btn-primary btn-sm">View Details</button>
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <Link to="/add-meal">
                <button className="btn btn-success w-100 mt-3">Add Meal</button>
            </Link>
        </div>
    );
};

export default Dashboard;
